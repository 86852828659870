
<template>
  <div>
    <header1Component
      :circleActive="2"
      :detailActive="2"
      :loopCircleSmall="5"
      :circleSmall="1"
      :changeCircle="1"
      :changeTitle="1"
    />
    <div class="container">
      <div class="card2">
        <div class="title conditionCard2">
          <div class="flex flex-row w-full">
            <div class="w-1/2" align="center">รายการ</div>
            <div class="w-1/2" align="center">จำนวนเงิน</div>
          </div>
        </div>

        <hr style="margin-top: 8px; margin-bottom: 13px" />

        <div
          class="title conditionCard2"
          style="margin-left: 33px; margin-right: 33px"
        >
          <div class="flex flex-row w-full">
            <div class="w-1/2" align="left">2. ค่าใช้สอยหรือค่าดำเนินการ</div>
            <div class="w-1/2" align="right">
              <div class="flex flex-wrap w-full">
                <div class="w-1/3">รวมทั้งสิ้น</div>
                <div class="w-1/3">0</div>
                <div class="w-1/3">บาท</div>
              </div>
            </div>
          </div>
        </div>

        <hr style="margin-top: 13px; margin-bottom: 11px" />

        <div v-for="(item, index) in costOperatingExpenses21" :key="index">
          <div
            class="conditionCard2"
            style="margin-left: 33px; margin-right: 33px"
          >
            <div class="title flex flex-row w-full">
              <div class="w-1/2" align="left">2.1 ค่าใช้สอย</div>
              <div class="w-1/2" align="right">
                <div class="flex flex-wrap w-full">
                  <div class="w-1/3">รวมทั้งสิ้น</div>
                  <div class="w-1/3">0</div>
                  <div class="w-1/3">บาท</div>
                </div>
              </div>
            </div>

            <div class="conditionCard2 flex flex-row">
              <div class="flex items-center mr-4 justify-end content" style="color: #242424;">
                รายละเอียด
              </div>

              <div class="w-full">
                <input type="text" placeholder="รายละเอียด">
              </div>
            </div>

            <div class="flex flex-row" style="margin-top: 31px">
              <div class="title" style="margin-right: 20px">รวม</div>
              <div class="title" style="margin-right: 20px">0</div>
              <div class="title">บาท</div>
            </div>

            <div class="content" style="color: #242424; margin-bottom: 15px">
              แหล่งข้องมูลอ้างอิง
            </div>

            <checkboxComponent
              class="mb-2"
              style="color: #575757;"
              :checkboxId="`checkbox1_${index}`"
              :name="`อัตราราคางานต่อหน่วย`"
              :value="`อัตราราคางานต่อหน่วย`"
              :underline="true"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />

            <checkboxComponent
              class="mb-2"
              style="color: #575757;"
              :checkboxId="`checkbox1_${index}`"
              :name="`หลักเกณฑ์ อัตราค่าใช้จ่ายและแนวทางการพิจารณางบประมาณรายจ่ายประจำปี การฝึกอบรม สัมนาการโฆษณา ประชาสัมพันธ์ การจ้างที่ปรึกษาคชจ.การ
เดินทางไปต่างประเทศ`"
              :value="`หลักเกณฑ์ อัตราค่าใช้จ่ายและแนวทางการพิจารณางบประมาณรายจ่ายประจำปี การฝึกอบรม สัมนาการโฆษณา ประชาสัมพันธ์ การจ้างที่ปรึกษาคชจ.การ
เดินทางไปต่างประเทศ`"
              :underline="true"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />

            <checkboxComponent
              class="mb-2"
              style="color: #575757;"
              :checkboxId="`checkbox1_${index}`"
              :name="`หลักเกณฑ์ และอัตราค่าใช้จ่าย ประกอบการพิจารณางบประมาณรายจ่ายประจำปี ที่เบิกจ่ายในลักษณะค่าตอบแทน ใช้สอย วัสดุ และค่าสาธารณูปโภค`"
              :value="`หลักเกณฑ์ และอัตราค่าใช้จ่าย ประกอบการพิจารณางบประมาณรายจ่ายประจำปี ที่เบิกจ่ายในลักษณะค่าตอบแทน ใช้สอย วัสดุ และค่าสาธารณูปโภค`"
              :underline="true"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />

            <checkboxComponent
              class="mb-2"
              style="color: #575757;"
              :checkboxId="`checkbox1_${index}`"
              :name="`หากไม่เป็นไปตามหลักเกณฑ์ของทางราชการจะต้องเป็นไปตามหลักการสืบราคาจากท้องตลาดโดยมีเอกสารอ้างอิงราคานั้นๆโดยใช้ใบเสนอ ราคา 3 ราย`"
              :value="`หากไม่เป็นไปตามหลักเกณฑ์ของทางราชการจะต้องเป็นไปตามหลักการสืบราคาจากท้องตลาดโดยมีเอกสารอ้างอิงราคานั้นๆโดยใช้ใบเสนอ ราคา 3 ราย`"
              :underline="true"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />

            <checkboxComponent
              class="mb-2"
              style="color: #575757;"
              :checkboxId="`checkbox1_${index}`"
              :name="`กรณีน้อยกว่า 3 รายการ กรุณาแนบใบเสนอราคา พร้อมระบุเหตุผล`"
              :value="`กรณีน้อยกว่า 3 รายการ กรุณาแนบใบเสนอราคา พร้อมระบุเหตุผล`"
              :underline="true"
              @click="checkboxCondition1(`checkbox1_${index}`)"
            />
          </div>
        </div>

        <hr />

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import header1Component from "@/components/capital/header1Component";
import checkboxComponent from "@/components/checkbox/checkboxComponent";
export default {
  components: {
    header1Component,
    checkboxComponent,
  },
  methods: {
    deleteCostOperatingExpenses21() {},
  },
  setup() {
    const costOperatingExpenses21 = ref([
      {
        detail: null,
        totalNumber: null,
        perUnitThb: null,
        dayNightTripMeal: null,
        referenceSource1: null,
        referenceSource2: null,
        referenceSource3: null,
        referenceSource4: null,
        referenceSource5: null,
      },
    ]);
    return { costOperatingExpenses21 };
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}

.circle {
  width: 40px;
  height: 40px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 36px;
}

.titleCircle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>